import moment from 'moment'

// Map tryout from backend format to frontend format
function mapTryout(obj) {
  return {
    date: moment(obj.date),
    endTime: moment(obj.end_time),
    fee: obj.fee,
    gender: obj.gender,
    maxAge: obj.max_age,
    minAge: obj.min_age,
    name: obj.name,
    registrationDate: moment(obj.registration_date),
    registrationUrl: obj.registration_url,
    spotsLeft: obj.spots_left,
    startTime: moment(obj.start_time),
  }
}

// Map array of tryout objects returned by API from backend format to frontend format
export function fromApi(objs) {
  const tryouts = objs.map(mapTryout)
  return tryouts
}
