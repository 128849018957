<template>
  <span class="font-weight-bold">{{ formattedName }}</span>
</template>

<script>
export default {
  props: {
    name: String,
  },
  computed: {
    formattedName() {
      const PREFIX_REGEX = /Week\s\d+:\s/
      const SUFFIX_REGEX = /\s\(.+\)/
      const NOTHING = ''
      const name = this.name.replace(PREFIX_REGEX, NOTHING).replace(SUFFIX_REGEX, NOTHING)
      return name
    },
  },
}
</script>
