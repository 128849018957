export default {
  // Menu
  title: 'Tricks & Tumbling',
  badge: null,
  description: 'Flips, Handsprings & More',

  // Route parameter
  name: 'tricks-and-tumbling',

  // Hero section
  hero: {
    title: 'Tricks & Tumbling',
    subtitle: 'Boys & Girls | Ages 8+',
    image: require('@/assets/hero-ninja.jpg'),
    position: 'center',
  },

  // Welcome section
  welcome: {
    title: 'Interested in learning how to do flips?',
    text: `Join this class to learn beginner to intermediate techniques and skills for tumbling. There is also a focus on improving flexibility and strength. This focused class is for athletes looking to take their tricks and flips to the next level.
    
    Skill Prerequisites: Strong Cartwheel, Balanced Handstand, Some Upper Body Strength, Advanced Coordination.
    
    Skill focus includes: Round Offs, Front & Back Handsprings, Front & Back Tucks`,
    buttonText: 'See available classes',
  },
}
