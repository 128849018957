<template>
  <div id="available-tryouts">
    <TryoutList :tryouts="tryouts" />
  </div>
</template>

<script>
import * as adapters from '@/adapters'

import TryoutList from './TryoutList'

export default {
  components: {
    TryoutList,
  },
  props: {
    sessionId: String,
    cat1: String,
  },
  data() {
    return {
      tryouts: [],
    }
  },
  async mounted() {
    this.setData()
  },
  methods: {
    async setData() {
      // Fetch tryouts from API
      const url = '/tryouts/'
      const payload = { session: this.sessionId, cat1: this.cat1 }
      const response = await this.$http.post(url, payload)
      // Map tryouts into frontend representation
      const tryouts = adapters.tryouts.fromApi(response.data)
      this.tryouts = tryouts
    },
  },
}
</script>

<style scoped>
#available-tryouts {
  background-image: url('../../assets/orange-background.jpg');
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>
