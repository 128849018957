<template>
  <v-btn
    v-if="isRegistrationOpen || isClassFull"
    small
    color="secondary"
    :disabled="isClassFull"
    rounded
    :href="registrationUrl"
  >
    {{ buttonText }}
  </v-btn>
  <span v-else>
    {{ registrationHint }}
  </span>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    spotsLeft: Number,
    registrationDate: Object,
    registrationUrl: String,
  },
  computed: {
    isRegistrationOpen() {
      const isOnlineRegistrationAllowed = Boolean(this.registrationUrl)
      const now = moment()
      const hasRegistrationDatePassed = now.isAfter(this.registrationDate)
      return isOnlineRegistrationAllowed && hasRegistrationDatePassed
    },
    registrationHint() {
      const formattedDate = this.registrationDate.format('ddd MMM D')
      const hint = `${formattedDate} @ 9pm`
      return hint
    },
    isClassFull() {
      const isClassFull = this.spotsLeft === 0
      return isClassFull
    },
    buttonText() {
      const text = this.isClassFull ? 'Already booked' : 'Register online'
      return text
    },
  },
}
</script>
