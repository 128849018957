<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <iframe
          width="100%"
          height="600px"
          frameborder="0"
          style="display: block"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2873.8471176160874!2d-78.94674618449356!3d43.92113917911298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d51ebdc75aea73%3A0x8307a75f1b0b5272!2s330%20Taunton%20Rd%20E%2C%20Whitby%2C%20ON%20L1R%200H4!5e0!3m2!1sen!2sca!4v1602775195130!5m2!1sen!2sca"
          allowfullscreen
        ></iframe>
      </v-col>
      <v-col id="contact-col" class="pa-12" cols="12" md="6">
        <v-row align="center" class="fill-height">
          <v-col>
            <p class="display-1 white--text">Have a question?</p>
            <ContactForm class="mt-12" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ContactForm from '@/components/ContactForm'

export default {
  components: {
    ContactForm,
  },
}
</script>

<style>
#contact-col {
  background-image: url('../assets/orange-background.jpg');
  background-size: cover;
}
</style>
