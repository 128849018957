export default {
  // Menu
  title: 'Summer Camp',
  badge: null,
  description: 'Runs July–August',

  // Route parameter & Jackrabbit identifiers
  name: 'summer',
  cat1: 'Camp',
  cat3s: [
    // 'Summer Camp schedule coming soon',
    // 'Week 1',
    // 'Week 2',
    // 'Week 3',
    // 'Week 4',
    // 'Week 5',
    // 'Week 6',
    // 'Week 7',
    // 'Week 8',
    // 'Week 9',
  ],

  // Hero section
  hero: {
    title: 'Summer Camp',
    subtitle: 'Girls & Boys | Ages 4+',
    image: require('@/assets/hero-camp.jpg'),
    position: 'center',
  },

  // Welcome section
  welcome: {
    title: `Thank you to everyone who participated in our Summer Camp 2024!`,
    text: `Whitby Gym & Circus proudly offers Summer Camp for kids. Our camp offers small groups doing focused activities. Campers spend the day with children their own age doing their chosen activities. Our camps prioritize safety, positive experience, and skill advancement.`,
    buttonText: 'See camp availability',
    identifier: '#available-camps',
  },

  // Whether to additionally display segregated camp info
  segregated: true,
}
