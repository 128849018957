import '@babel/polyfill'

import App from './App'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueGtag, { pageview } from 'vue-gtag'

// Initialize Google Analytics
const GA4_MEASUREMENT_IDS = { local: 'G-DKR0XYWZY1', dev: 'G-V5JDJ2NDPF', prod: 'G-RER0K7910Y' }
const stage = process.env.VUE_APP_STAGE
const measurementId = GA4_MEASUREMENT_IDS[stage]
const config = { id: measurementId }
const options = { config }
Vue.use(VueGtag, options, router) // TODO Unpredictably reports one of document.title, route.name, or route.fullPath

// Integrate Axios into Vue
Vue.use(VueAxios, axios)

// Globally define Parent Portal URL for use throughout app
Vue.prototype.$parentPortal = 'https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgId=524322'

// Initialize and mount Vue instance
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
