<template>
  <v-container>
    <!-- TODO Restructure:
      From Parties > index > PartyList > for loop over instructions
      To PartyPage > PartyList (for loop over Party) > Party with instructions
    -->
    <v-card class="mt-12 elevation-0">
      <!-- Title -->
      <v-card-title>Register for a birthday party!</v-card-title>

      <!-- Data table -->
      <v-data-table :headers="headers" :items="parties" hide-default-footer :items-per-page="-1">
        <!-- Name column -->
        <template v-slot:item.name="{ item }">
          <ColumnName v-bind="item" />
        </template>

        <!-- Date column -->
        <template v-slot:item.date="{ item }">
          <ColumnDate v-bind="item" />
        </template>

        <!-- Time column -->
        <template v-slot:item.startTime="{ item }">
          <ColumnTime v-bind="item" />
        </template>

        <!-- Fee column -->
        <template v-slot:item.fee="{ item }">
          <ColumnFee v-bind="item" />
        </template>

        <!-- Registration button column -->
        <template v-slot:item.registrationUrl="{ item }">
          <ColumnRegistration v-bind="item" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import ColumnName from './ColumnName'
import ColumnDate from './ColumnDate'
import ColumnTime from './ColumnTime'
import ColumnFee from './ColumnFee'
import ColumnRegistration from './ColumnRegistration'

export default {
  components: {
    ColumnName,
    ColumnDate,
    ColumnTime,
    ColumnFee,
    ColumnRegistration,
  },
  props: {
    parties: Array,
  },
  data() {
    // Generate table headers
    const headers = [
      { text: 'Available parties', value: 'name' },
      { text: 'Date', value: 'date' },
      { text: 'Times', value: 'startTime' },
      { text: 'Booking deposit', value: 'fee', align: 'right' },
      { text: 'Registration', value: 'registrationUrl', align: 'right' },
    ]

    return {
      headers,
    }
  },
}
</script>
