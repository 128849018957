<template>
  <v-app>
    <!-- Mobile menu -->
    <v-navigation-drawer app v-model="drawer" stateless>
      <v-list nav dense>
        <template v-for="item in menu">
          <div v-if="item.items" :key="item.title">
            <NavDrawerItemNested v-for="subitem in item.items" :key="subitem.title" v-bind="subitem" />
          </div>
          <NavDrawerItem v-else :key="item.title" v-bind="item" />
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- App bar -->
    <v-app-bar color="primary" dark>
      <v-app-bar-nav-icon v-if="mobile" @click.stop="drawer = !drawer" />
      <router-link v-else :to="{ name: 'home' }">
        <v-img
          alt="Whitby Gym &amp; Circus logo"
          contain
          :src="require('@/assets/logo-icon-white.png')"
          :height="25"
          :width="25"
          class="mr-8"
        />
      </router-link>
      <v-toolbar-title>
        <router-link :to="{ name: 'home' }" class="white--text" style="text-decoration: none">
          Whitby Gym &amp; Circus
        </router-link>
      </v-toolbar-title>
      <v-spacer />

      <!-- Desktop menu -->
      <div v-show="!mobile">
        <template v-for="item in menu">
          <AppBarMenu v-if="item.items" :key="item.title" v-bind="item" />
          <AppBarItem v-else :key="item.title" v-bind="item" />
        </template>
      </div>
    </v-app-bar>

    <!-- Content -->
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import AppBarMenu from '@/components/AppBarMenu'
import AppBarItem from '@/components/AppBarItem'
import NavDrawerItem from '@/components/NavDrawerItem'
import NavDrawerItemNested from '@/components/NavDrawerItemNested'

import SESSIONS from '@/assets/sessions'
import CAMPS from '@/assets/camps'

export default {
  components: {
    AppBarMenu,
    AppBarItem,
    NavDrawerItem,
    NavDrawerItemNested,
  },
  data() {
    return {
      drawer: false,
      // TODO replace route strings with route path constants
      menu: [
        {
          title: 'Classes',
          badge: null,
          items: this.menuifySession(SESSIONS),
        },
        {
          title: 'Camps',
          badge: null,
          items: this.menuifyCamps(CAMPS),
        },
        {
          title: 'Parties',
          badge: null,
          to: { name: 'parties' },
        },
        {
          title: 'Competitive',
          badge: null,
          to: { name: 'competitive' },
        },
        {
          title: 'Parent Portal',
          badge: null,
          href: this.$parentPortal,
        },
      ],
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
  },
  created() {
    this.$http.defaults.baseURL = process.env.VUE_APP_BACKEND_URL // Set backend URL
  },
  methods: {
    menuifySession(sessions) {
      return sessions
        .filter(session => !session.test)
        .map(session => ({
          badge: session.badge,
          title: session.title,
          description: session.description,
          disabled: session.disabled,
          items: session.disciplines.map(discipline => ({
            badge: discipline.badge,
            title: discipline.title,
            description: discipline.description,
            to: { name: 'session', params: { session: session.name, discipline: discipline.name } },
          })),
        }))
    },
    menuifyCamps(camps) {
      return camps.map(camp => ({
        badge: camp.badge,
        title: camp.title,
        description: camp.description,
        disabled: camp.disabled,
        to: { name: 'camps', params: { camp: camp.name } },
      }))
    },
  },
  watch: {
    $route() {
      // Close nav drawer on route change
      this.drawer = false
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/styles.scss'; // Necessary for fancy font throughout app
</style>
