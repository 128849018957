<template>
  <v-card class="py-6 px-10">
    <v-row class="mb-2">
      <v-col class="headline font-fancy">
        {{ title }}
      </v-col>
      <v-col class="headline text-right font-fancy">
        <span class="secondary--text">${{ price }}</span> + HST
      </v-col>
    </v-row>

    <v-row v-for="item in items" :key="item" dense>
      <v-col cols="auto">
        <v-icon color="secondary">mdi-check-circle-outline</v-icon>
      </v-col>
      <v-col>{{ item }}</v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    price: String,
    items: Array,
  },
}
</script>
