<template>
  <v-list-item two-line :to="to" exact :href="href" :target="href ? '_blank' : null">
    <AppBarItemNestedContent v-bind="$props" />
  </v-list-item>
</template>

<script>
import AppBarItemNestedContent from '@/components/AppBarItemNestedContent'

export default {
  components: {
    AppBarItemNestedContent,
  },
  props: {
    title: String,
    badge: String,
    description: String,
    to: Object,
    href: String,
  },
}
</script>
