<template>
  <div class="py-1">
    <ClassDetailsModal v-for="(timeslot, i) in timeslots" :key="i" v-bind="{ ...info, ...timeslot }" />
  </div>
</template>

<script>
import ClassDetailsModal from './ClassDetailsModal'

export default {
  components: {
    ClassDetailsModal,
  },
  props: {
    info: Object,
    timeslots: Array,
  },
}
</script>
