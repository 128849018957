export const PARENT_AND_TOT = {
  title: 'Parent & Tot',
  gender: 'Boys & Girls',
  ages: 'Walking age–2 years',
  description: `This 45-minute class is designed to introduce young toddlers to the world of gymnastics and structured group lessons. The main focus is to enhance the child's gross motor and social skills. Participants can start when walking and up to age 2. One parent is required to participate in this class.`,
  cat1: 'Parent and Tot',
}

export const TOTS_KINDERGYM = {
  title: 'Tots KinderGym',
  gender: 'Boys & Girls',
  ages: 'Ages 2–3',
  requiresParent: true,
  description: `This 45-minute class is for toddlers ages 2–3, and focuses on continuing to develop the motor skills and movement patterns that make for a strong gymnastics foundation.`,
  cat1: 'Tots KinderGym',
}

export const JR_KINDERGYM = {
  title: 'Jr. KinderGym',
  gender: 'Boys & Girls',
  ages: 'Ages 3–4',
  requiresParent: true,
  description: `This 55-minute class is designed to introduce toddlers ages 3–4 to more technical instruction. The focus is to continue learning and mastering gross motor skills, social skills, and understanding structured learning environments. The group will enjoy fun-filled lessons on the 4 events of girls gymnastics: Vault, Bars, Beam and Floor. They will also have time for a variety of activities through songs and games that will vary from week to week.`,
  cat1: 'Jr. KinderGym',
}

export const SR_KINDERGYM = {
  title: 'Sr. KinderGym',
  gender: 'Boys & Girls',
  ages: 'Ages 5–6',
  description: `This 70-minute class is for children ages 5–6 who understand the structured learning environment and are ready to focus on more skill development. Exciting lessons will introduce a new level of gymnastic skill sets. These lessons will include 4 events and additional activities through songs and games that will vary from week to week. Children from this class may try out for competitive classes.`,
  cat1: 'Sr. KinderGym',
}

export const JR_BOYS_GYM = {
  title: 'Jr. Boys Gym',
  gender: 'Boys',
  ages: 'Ages 7–9',
  description: `This class is for boys only ages 7–9 years old, and runs for 1 hour 25 minutes. These athletes will begin to work through our proprietary gymnastics level system. Through the session they will have the opportunity to complete a level, progress and master new skills in the 6 events of men's gymnastics. Classes will be structured using circuits and stations that can be tailored for the individual's beginner or advanced needs.`,
  cat1: 'Jr. Boys Gym',
}

export const JR_GIRLS_GYM = {
  title: 'Jr. Girls Gym',
  gender: 'Girls',
  ages: 'Ages 7–9',
  description: `This class is for girls only ages 7–9 years old, and runs for 1 hour 25 minutes. These athletes will begin to work on our professionally developed custom level system. Throughout the session they will have the opportunity to complete a level, progress and master new skills in the 4 events of women's gymnastics. Classes will be structured using circuits and stations that can be tailored for the individual's beginner or advanced needs. Athletes from this class may try out for competitive classes.`,
  cat1: 'Jr. Girls Gym',
}

export const SR_GIRLS_GYM = {
  title: 'Sr. Girls Gym',
  gender: 'Girls',
  ages: 'Ages 10–17',
  description: `This class is for girls only ages 10–17 of beginner to advanced skill levels. The class runs for 1 hour 25 minutes. They will work through our professional developed custom level system and they will have the opportunity to create mock routines on Bars, Beam, and Floor. These routines can be presented to their parents/guardians at the end of the session if they wish.`, // TODO Probably shouldn't say "girls only" because politics
  cat1: 'Sr. Girls Gym',
}
