import moment from 'moment'

// Map camp from backend format to frontend format
function mapCamp(obj) {
  return {
    cat3: obj.cat3,
    endDate: moment(obj.end_date),
    endTime: moment(obj.end_time),
    fee: obj.fee,
    gender: obj.gender,
    maxAge: obj.max_age,
    minAge: obj.min_age,
    name: obj.name,
    registrationDate: moment(obj.registration_date),
    registrationUrl: obj.registration_url,
    spotsLeft: obj.spots_left,
    startDate: moment(obj.start_date),
    startTime: moment(obj.start_time),
  }
}

function mapWeek(obj) {
  return {
    cat3: obj.cat3,
    camps: obj.camps.map(mapCamp),
  }
}

// Map weeks object returned by API from backend format to frontend format
export function fromApi(objs) {
  const weeks = objs.map(mapWeek)
  return weeks
}
