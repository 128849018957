import { DAYS_OF_WEEK } from '@/constants'
import moment from 'moment'

// Map timeslot from backend format to frontend format
function mapTimeslot(obj) {
  return {
    endDate: moment(obj.end_date),
    endTime: moment(obj.end_time),
    fee: obj.fee,
    registrationDate: moment(obj.registration_date),
    registrationUrl: obj.registration_url,
    spotsLeft: obj.spots_left,
    startDate: moment(obj.start_date),
    startTime: moment(obj.start_time),
  }
}

// Map class from API format into table display format, generating class info from class types
function mapClass(classType, class_) {
  let mappedClass = { info: classType }
  for (const DAY_OF_WEEK of DAYS_OF_WEEK) mappedClass[DAY_OF_WEEK] = class_[DAY_OF_WEEK].map(mapTimeslot)
  return mappedClass
}

// Map classes object returned by API from backend format to frontend format
export function fromApi(classTypes, classes) {
  return classes.map(class_ => {
    const classType = classTypes.find(ct => ct.cat1 === class_.cat1)
    const mappedClass = mapClass(classType, class_)
    return mappedClass
  })
}
