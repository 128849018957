<template>
  <v-list-item-content>
    <v-list-item-title>
      <span v-if="badge === null">{{ title }}</span>
      <v-badge v-else color="secondary" :content="badge">{{ title }}</v-badge>
    </v-list-item-title>
    <v-list-item-subtitle>
      <span :class="{ 'text--disabled': disabled }">{{ description }}</span>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
export default {
  props: {
    title: String,
    badge: String,
    description: String,
    disabled: Boolean,
  },
}
</script>
