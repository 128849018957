<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="mx-1">
        <span v-if="badge === null">{{ title }}</span>
        <v-badge v-else color="secondary" :content="badge">{{ title }}</v-badge>
      </v-btn>
    </template>

    <v-list>
      <div v-for="item in items" :key="item.title">
        <AppBarMenuNested v-if="item.items" v-bind="item" />
        <AppBarItemNested v-else :key="item.title" v-bind="item" />
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import AppBarMenuNested from '@/components/AppBarMenuNested'
import AppBarItemNested from '@/components/AppBarItemNested'

export default {
  components: {
    AppBarMenuNested,
    AppBarItemNested,
  },
  props: {
    title: String,
    badge: String,
    items: Array,
  },
}
</script>
