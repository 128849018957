import { ACRO_FOR_DANCERS, ADULT_GYM } from '@/assets/classTypes/acro'
import { ADULT_CIRCUS, JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS } from '@/assets/classTypes/circus'
import {
  JR_BOYS_GYM,
  JR_GIRLS_GYM,
  JR_KINDERGYM,
  PARENT_AND_TOT,
  SR_GIRLS_GYM,
  SR_KINDERGYM,
  TOTS_KINDERGYM,
} from '@/assets/classTypes/gymnastics'
import { JR_NINJA, SR_NINJA } from '@/assets/classTypes/ninja'

import ACRO from '@/assets/disciplines/acro'
import CIRCUS from '@/assets/disciplines/circus'
import GYMNASTICS from '@/assets/disciplines/gymnastics'
import NINJA from '@/assets/disciplines/ninja'

export default {
  // Menu
  title: 'Winter Session',
  badge: null,
  description: 'Runs January–April',
  disabled: true,

  // Route parameter & Jackrabbit identifier
  name: 'winter',
  id: 'Winter', // Session identifier in Jackrabbit, e.g. Winter 2023

  // Disciplines & classes
  disciplines: [
    {
      ...GYMNASTICS,
      classTypes: [PARENT_AND_TOT, TOTS_KINDERGYM, JR_KINDERGYM, SR_KINDERGYM, JR_BOYS_GYM, JR_GIRLS_GYM, SR_GIRLS_GYM],
    },
    { ...CIRCUS, classTypes: [JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS, ADULT_CIRCUS] },
    { ...ACRO, classTypes: [ACRO_FOR_DANCERS, ADULT_GYM] },
    { ...NINJA, classTypes: [JR_NINJA, SR_NINJA] },
  ],

  // Registration date announcement & session details
  announcement: `🗓️ Pre-registration begins November 19th at 9pm
  🗓️ Open registration begins November 26th at 9pm`,
  details: `This session runs on the following dates:

  • Saturday classes: January 6th–March 23rd (9 weeks)
  • Sunday classes: January 7th–March 24th (9 weeks)
  • Monday classes: January 8th–March 25th (9 weeks)
  • Tuesday classes: January 9th–March 26th (12 weeks)
  • Wednesday classes: January 10th–March 27th (10 weeks)
  • Thursday classes: January 11th–March 28th (10 weeks)

  Classes will not run:
  • January 24th–29th (Competition)
  • February 16th–19th (Family Day Weekend)
  • February 28th–March 4th (Competition)
  • March 29th–April 1st (Easter Weekend)

  Each participant must have valid Gymnastics Ontario insurance. A non-refundable insurance fee of $46 + HST applies to each as-of-yet uninsured participant, and is valid until next July.`,
}
