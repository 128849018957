<template>
  <span>{{ formattedAgeRange }}</span>
</template>

<script>
export default {
  props: {
    minAge: Object,
    maxAge: Object,
  },
  computed: {
    formattedAgeRange() {
      const formattedMinAge = this.formatAge(this.minAge)
      const formattedMaxAge = this.formatAge(this.maxAge)
      const secondHalf = formattedMaxAge ? `–${formattedMaxAge}` : '+'
      const formattedAgeRange = `${formattedMinAge}${secondHalf}`
      return formattedAgeRange
    },
  },
  methods: {
    formatAge(age) {
      let expression = []
      if (age.years) {
        let years = age.years + ' year'
        if (age.years > 1) years += 's' // Conditionally pluralize
        expression.push(years)
      }
      if (age.months) {
        let months = age.months + ' month'
        if (age.months > 1) months += 's' // Conditionally pluralize
        expression.push(months)
      }
      return expression.join(', ')
    },
  },
}
</script>
