<template>
  <v-btn text class="mx-1" :to="to" exact :href="href" :target="href ? '_blank' : null">
    <span v-if="badge === null">{{ title }}</span>
    <v-badge v-else color="secondary" :content="badge">{{ title }}</v-badge>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: String,
    badge: String,
    to: Object,
    href: String,
  },
}
</script>
