<template>
  <div>
    <v-btn class="mt-3" :href="link" target="_blank" dark text>
      <v-icon left>{{ icon }}</v-icon>
      {{ text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
    link: String,
  },
}
</script>

<style scoped>
::v-deep .v-btn__content {
  justify-content: unset;
}
</style>
