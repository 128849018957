export default {
  // Menu
  title: 'P.A. Day Camp',
  badge: null,
  description: 'Runs November 17th',

  // Route parameter & Jackrabbit identifiers
  name: 'pa-day',
  cat1: 'Camp',
  cat3s: ['Week 1'],

  // Hero section
  hero: {
    title: 'P.A. Day Camp',
    subtitle: 'Girls & Boys | Ages 4+',
    image: require('@/assets/hero-camp.jpg'),
    position: 'center',
  },

  // Welcome section
  welcome: {
    title: 'P.A. Day Camp is on!',
    text: `Whitby Gym & Circus proudly offers P.A. Day Camp for kids. Our camp offers small groups doing focused activities. Campers spend the day with children their own age doing their chosen activities. Our camps prioritize safety, positive experience, and skill advancement.`,
    buttonText: 'See camp availability',
    identifier: '#available-camps',
  },

  // Whether to additionally display segregated camp info
  segregated: false,
}
