<template>
  <v-card class="elevation-0">
    <!-- Search input -->
    <v-card-title v-if="!mobile.is">
      <v-text-field
        label="Search for a class, a day, a time, anything!"
        append-icon="mdi-magnify"
        v-model="search"
        hide-details
        single-line
        filled
      />
    </v-card-title>

    <!-- Data table -->
    <v-data-table
      :headers="headers"
      :items="rows"
      :search="search"
      hide-default-footer
      :items-per-page="-1"
      :mobile-breakpoint="mobile.threshold"
    >
      <!-- Class column -->
      <template v-slot:item.info="{ item }">
        <InfoColumn v-bind="item.info" />
      </template>

      <!-- Monday column -->
      <template v-slot:item.Monday="{ item }">
        <TimeslotsColumn :info="item.info" :timeslots="item.Monday" />
      </template>

      <!-- Tuesday column -->
      <template v-slot:item.Tuesday="{ item }">
        <TimeslotsColumn :info="item.info" :timeslots="item.Tuesday" />
      </template>

      <!-- Wednesday column -->
      <template v-slot:item.Wednesday="{ item }">
        <TimeslotsColumn :info="item.info" :timeslots="item.Wednesday" />
      </template>

      <!-- Thursday column -->
      <template v-slot:item.Thursday="{ item }">
        <TimeslotsColumn :info="item.info" :timeslots="item.Thursday" />
      </template>

      <!-- Friday column -->
      <template v-slot:item.Friday="{ item }">
        <TimeslotsColumn :info="item.info" :timeslots="item.Friday" />
      </template>

      <!-- Saturday column -->
      <template v-slot:item.Saturday="{ item }">
        <TimeslotsColumn :info="item.info" :timeslots="item.Saturday" />
      </template>

      <!-- Sunday column -->
      <template v-slot:item.Sunday="{ item }">
        <TimeslotsColumn :info="item.info" :timeslots="item.Sunday" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import * as adapters from '@/adapters'
import InfoColumn from './InfoColumn'
import TimeslotsColumn from './TimeslotsColumn'

// Define a column header for each day of the week
import { DAYS_OF_WEEK } from '@/constants'

export default {
  components: {
    InfoColumn,
    TimeslotsColumn,
  },
  props: {
    sessionId: String,
    classTypes: Array,
  },
  data() {
    // Generate table headers
    const classHeader = { text: 'Class', value: 'info', width: 210 }
    const makeDayOfWeekHeader = dayOfWeek => ({ text: dayOfWeek, value: dayOfWeek, align: 'center' })
    const dayOfWeekHeaders = DAYS_OF_WEEK.map(makeDayOfWeekHeader)
    const headers = [classHeader, ...dayOfWeekHeaders]

    return {
      headers,
      rows: [],
      search: '',
    }
  },
  computed: {
    mobile() {
      const MOBILE_SIZE = 'md'
      const breakpoint = `${MOBILE_SIZE}AndDown`
      const is = this.$vuetify.breakpoint[breakpoint]
      const threshold = this.$vuetify.breakpoint.thresholds[MOBILE_SIZE]
      return { is, threshold }
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    async setData() {
      // Fetch classes from API
      const url = '/classes/'
      const cat1s = this.classTypes.map(classType => classType.cat1)
      const payload = { session: this.sessionId, cat1s }
      const response = await this.$http.post(url, payload)
      // Map classes into table display format
      const classes = adapters.classes.fromApi(this.classTypes, response.data)
      this.rows = classes // Display classes in data table
    },
  },
  watch: {
    $route() {
      this.setData()
    },
  },
}
</script>

<style scoped>
/* Style table columns in alternating white & grey */
tbody tr td:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}

/* Spatially separate table rows on mobile */
table {
  background-color: var(--v-primary-base);
}
.v-data-table__mobile-table-row:hover {
  background-color: white !important;
}
tr {
  margin-top: 36px;
  background-color: white;
}

/* Hide sort dropdown on mobile */
.v-data-table-header-mobile {
  display: none;
}
</style>
