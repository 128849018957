<template>
  <span>{{ formattedTimeRange }}</span>
</template>

<script>
export default {
  props: {
    startTime: Object,
    endTime: Object,
  },
  computed: {
    formattedTimeRange() {
      const formattedStartTime = this.formatTime(this.startTime)
      const formattedEndTime = this.formatTime(this.endTime)
      const formattedTimeRange = `${formattedStartTime}–${formattedEndTime}`
      return formattedTimeRange
    },
  },
  methods: {
    formatTime(time) {
      return time.format('h:mma')
    },
  },
}
</script>
