<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
export default {
  props: {
    date: Object,
  },
  computed: {
    formattedDate() {
      return this.formatDate(this.date)
    },
  },
  methods: {
    formatDate(date) {
      return date.format('ddd MMM D')
    },
  },
}
</script>
