<template>
  <main>
    <Hero
      title="Competitive Girls Artistic"
      subtitle="Girls | Ages 5+"
      :image="require('@/assets/hero-competitive.jpg')"
      position="center"
    />

    <CompetitiveInfo />

    <TryoutList sessionId="2023-2024" cat1="Testing Fee" />

    <MailingListSignup />

    <Footer />
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import CompetitiveInfo from '@/components/CompetitiveInfo'
import Footer from '@/components/Footer'
import MailingListSignup from '@/components/MailingListSignup'
import TryoutList from '@/components/TryoutList'

export default {
  components: {
    Hero,
    CompetitiveInfo,
    Footer,
    MailingListSignup,
    TryoutList,
  },
}
</script>
