export default {
  // Menu
  title: 'Acro Classes',
  badge: null,
  description: 'Acro Skills for Dancers & Adults',

  // Route parameter
  name: 'acro',

  // Hero section
  hero: {
    title: 'Acro Classes',
    subtitle: 'Girls & Boys | Ages 8–17',
    image: require('@/assets/hero-acro.jpg'),
    position: 'center',
  },

  // Welcome section
  welcome: {
    title: 'Give Your Dance the Competitive Edge',
    text: `Accomplished acro dancers often do specialized training at gymnastics clubs. Whitby Gym & Circus is proud to offer acro classes that accelerate dancers' skills in a safe and practical way.`,
    buttonText: 'See available classes',
  },
}
