<template>
  <span>{{ formattedDateRange }}</span>
</template>

<script>
export default {
  props: {
    startDate: Object,
    endDate: Object,
  },
  computed: {
    formattedDateRange() {
      const formattedStartDate = this.formatDate(this.startDate)
      const formattedEndDate = this.formatDate(this.endDate)
      const formattedDateRange = `${formattedStartDate}–${formattedEndDate}`
      return formattedDateRange
    },
  },
  methods: {
    formatDate(date) {
      return date.format('ddd MMM D')
    },
  },
}
</script>
