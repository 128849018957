<template>
  <main>
    <Hero
      title="Birthday Parties"
      subtitle="Boys &amp; Girls | All Ages"
      :image="require('@/assets/hero-party.jpg')"
      position="center"
    />

    <section id="party-info" class="grey darken-4 white--text">
      <v-container>
        <p class="display-1 font-weight-regular font-fancy">
          Birthday parties are offered on Saturdays at 2:30pm and 3:00pm.
        </p>
        <p>In-gym activities are structured and lesson-based. Activities are not self-directed "free play."</p>
        <p>
          Participants should arrive 15 minutes prior to the start of the party. Extra time will not be afforded to late
          arrivals. During the party, exclusive use of the facility is not guaranteed.
        </p>
        <p>
          Adults may watch the party from our parent viewing area. Adults are permitted to accompany their child in the
          gym area only if their participating child is 2 years of age or younger. Accompanying adults are not permitted
          on the equipment at any time.
        </p>
        <p>
          Host family to provide all food, either brought from home or ordered to facility. All food brought or ordered
          <strong>must be nut-free</strong>.
        </p>
        <p>
          Please ask your participating guests to wear clothing that is free of any buttons, clips, zippers, and strings
          to ensure they can participate in all of our amazing birthday activities!
        </p>
        <p>All package prices are subject to HST.</p>
      </v-container>
    </section>

    <section id="party-themes">
      <v-container>
        <p class="headline text-center">Your party can be themed!</p>
        <v-row class="mt-12">
          <v-col class="text-center">
            <v-icon :size="60" color="secondary">mdi-check-circle-outline</v-icon>
            <p class="display-1 mt-4 font-fancy">Gymnastics</p>
            <p>Vault, Bars, Beam, Floor &amp; Tumble Track</p>
          </v-col>
          <v-col class="text-center">
            <v-icon :size="60" color="secondary">mdi-check-circle-outline</v-icon>
            <p class="display-1 mt-4 font-fancy">Circus</p>
            <p>Aerial Silks, Trapeze, Aerial Hoop &amp; Tumble Track</p>
            <p class="caption">
              (We can facilitate just one circus-themed party at a time. Circus theme bookings are on first come first
              served basis.)
            </p>
          </v-col>
          <v-col class="text-center">
            <v-icon :size="60" color="secondary">mdi-check-circle-outline</v-icon>
            <p class="display-1 mt-4 font-fancy">Gladiator</p>
            <p>Team Games, Partner Challenges &amp; Obstacle Course Races</p>
          </v-col>
        </v-row>
        <p class="mt-12 caption text-center">
          Because we staff your child's party with coaches specialized for your chosen theme, we cannot offer parties
          that combine multiple themes
        </p>
      </v-container>
    </section>

    <section id="party-offers">
      <v-container>
        <v-row>
          <v-col>
            <PartyCard
              title="Package 1"
              price="225"
              :items="[
                '90 minutes in the gym',
                'Up to 10 participants',
                '1 Coach, 1 Junior Coach',
                'Party room rental throughout',
              ]"
            />
          </v-col>

          <v-col>
            <PartyCard
              title="Package 2"
              price="265"
              :items="[
                '90 minutes in the gym',
                'Up to 15 participants',
                '2 Coaches, 1 Junior Coach',
                'Party room rental throughout',
              ]"
            />
          </v-col>

          <v-col>
            <PartyCard
              title="Package 3"
              price="295"
              :items="[
                '90 minutes in the gym',
                'Up to 20 participants',
                '2 Coaches, 1 Junior Coach',
                'Party room rental throughout',
              ]"
            />
          </v-col>
        </v-row>

        <PartyList sessionId="Birthday 2025" cat1="Birthday Party" />
      </v-container>
    </section>

    <Footer />
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import PartyCard from '@/components/PartyCard'
import Footer from '@/components/Footer'
import PartyList from '@/components/PartyList'

export default {
  components: {
    Hero,
    PartyCard,
    Footer,
    PartyList,
  },
}
</script>

<style scoped>
#party-offers {
  background-image: url('../assets/orange-background.jpg');
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
#party-info {
  background-image: url('../assets/black-tile.jpg');
  background-repeat: repeat;
  padding-top: 100px;
  padding-bottom: 100px;
}
#party-themes {
  background-image: url('../assets/grey-background.jpg');
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>
