import Camp from '@/views/Camp'
import Competitive from '@/views/Competitive'
import Home from '@/views/Home'
import PageNotFound from '@/views/PageNotFound'
import Parties from '@/views/Parties'
import Session from '@/views/Session'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/classes/:session/:discipline',
    name: 'session',
    component: Session,
  },
  {
    path: '/camps/:camp',
    name: 'camps',
    component: Camp,
  },
  {
    path: '/parties',
    name: 'parties',
    component: Parties,
  },
  {
    path: '/competitive',
    name: 'competitive',
    component: Competitive,
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // Scroll to top of page when route changes
    return { x: 0, y: 0 }
  },
})

export default router
