<template>
  <div class="my-6">
    <!-- Class title & description -->
    <div>
      <span class="class-title font-fancy font-weight-regular">{{ title }}</span>
      <v-tooltip right max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="secondary" size="20" class="ml-2" style="margin-top: -6px" v-bind="attrs" v-on="on">
            mdi-information
          </v-icon>
        </template>
        <span>{{ description }}</span>
      </v-tooltip>
    </div>

    <!-- Additional class information -->
    <div>{{ gender }}</div>
    <div>{{ ages }}</div>
  </div>
</template>

<script>
export default {
  props: {
    ages: String,
    description: String,
    gender: String,
    title: String,
  },
}
</script>

<style scoped>
.class-title {
  font-size: 150%;
}
</style>
