export default {
  // Menu
  title: 'Circus Classes',
  badge: null,
  description: 'Aerial Silks, Static Trapeze & Lyra',

  // Route parameter
  name: 'circus',

  // Hero section
  hero: {
    title: 'Circus Classes',
    subtitle: 'Boys & Girls | Ages 6+',
    image: require('@/assets/hero-circus.jpg'),
    position: 'center',
  },

  // Welcome section
  welcome: {
    title: 'Join the Circus',
    text: `Whitby Gym & Circus is proud to offer circus classes for boys, girls, and adults! Learn to perform on the aerial silks, the static trapeze, and the aerial hoop. You've never had so much fun as this.`,
    buttonText: 'See available classes',
  },
}
