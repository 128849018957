<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        :color="isClassFull ? null : 'secondary'"
        :class="{ 'font-weight-regular text--disabled': isClassFull }"
        :style="{ 'text-decoration': isClassFull ? 'line-through' : 'underline' }"
        v-bind="attrs"
        v-on="on"
      >
        {{ formattedStartTime }}
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    formattedStartTime: String,
    isClassFull: Boolean,
    tooltipText: String,
  },
}
</script>
