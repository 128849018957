<template>
  <span>{{ formattedFee }}</span>
</template>

<script>
export default {
  props: {
    fee: Number,
  },
  computed: {
    formattedFee() {
      return `$${this.fee}`
    },
  },
}
</script>
