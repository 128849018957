export default {
  // Menu
  title: 'Gymnastics Classes',
  badge: null,
  description: 'Vault, Bars, Beam & Floor',

  // Route parameter
  name: 'gymnastics',

  // Hero section
  hero: {
    title: 'Gymnastics Classes',
    subtitle: 'Boys & Girls | All Ages',
    image: require('@/assets/hero-gymnastics.jpg'),
    position: 'bottom',
  },

  // Welcome section
  welcome: {
    title: 'Welcome to The Club',
    text: `Whitby Gym & Circus is proud to offer gymnastics classes for boys and girls of all ages. We're known for emphasizing skill development through our structured and fast-paced classes. Our experienced coaching team leads our gymnasts through our proprietary level system which emphasizes mastery, safety, and technical precision.`,
    buttonText: 'See available classes',
  },
}
